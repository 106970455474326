import { PortDirectoryHeaderDTO } from '@state/types/PortDirectoryHeaderDTO';
import { ContactCardDetails } from '@state/types/ContactCardDetails';
import { portAnalysisApi } from './portAnalysisServiceApi';
import { PortDirectoryAdditionalInformationDTO } from '@state/types/PortDirectoryAdditionalInformationDTO';
import { PortDirectoryPortDTO } from '@state/types/PortDirectoryPortDTO';

const BASE_URL = '/port-directory';

const portDirectoryAPI = portAnalysisApi.injectEndpoints({
  endpoints: (build) => ({
    fetchPortDirectoryHeader: build.query<PortDirectoryHeaderDTO, number>({
      query: (portId) => ({
        url: `${BASE_URL}/header/port/${portId}`,
        method: 'GET'
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [{ type: 'PORT_DIRECTORY_HEADER' as const, id: arg }]
          : ['PORT_DIRECTORY_HEADER']
    }),
    fetchSuppliers: build.query<ContactCardDetails[], number>({
      query: (portId) => ({
        url: `${BASE_URL}/supplier/port/${portId}`,
        method: 'GET'
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [{ type: 'PORT_DIRECTORY_SUPPLIERS' as const, id: arg }]
          : ['PORT_DIRECTORY_SUPPLIERS']
    }),
    fetchPortDirectoryPort: build.query<PortDirectoryPortDTO, string>({
      query: (portName) => ({
        url: `${BASE_URL}/port/${portName}`,
        method: 'GET'
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [{ type: 'PORT_DIRECTORY_PORT' as const, portName: arg }]
          : ['PORT_DIRECTORY_PORT']
    }),
    fetchPortAdditionalInformation: build.query<
      PortDirectoryAdditionalInformationDTO,
      number
    >({
      query: (portId) => ({
        url: `${BASE_URL}/port-information/port/${portId}/additional-information`,
        method: 'GET'
      }),
      providesTags: (res, _error, arg) =>
        res
          ? [{ type: 'PORT_DIRECTORY_ADDITIONAL_INFO' as const, id: arg }]
          : ['PORT_DIRECTORY_ADDITIONAL_INFO']
    })
  }),
  overrideExisting: false
});

export const {
  useFetchPortDirectoryHeaderQuery,
  useLazyFetchPortDirectoryHeaderQuery,
  useFetchPortDirectoryPortQuery,
  useLazyFetchPortDirectoryPortQuery,
  useFetchPortAdditionalInformationQuery,
  useFetchSuppliersQuery
} = portDirectoryAPI;
